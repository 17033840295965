import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Notification from "../components/Notification"

const Datenschutz = () => (
  <Layout>
    <SEO title="TDCS Rosenheim" />
    <Notification />
    <h1>Datenschutz</h1>

    <h2>Datenschutzerklärung nach der DSGVO</h2>
    <h3>Name und Anschrift des Verantwortlichen</h3>
    <p>
      Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer
      nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
      datenschutzrechtlicher Bestimmungen ist die:
    </p>
    <p>
      Dr. Badoiu George Vichente
      <br />
      83075 Bad Feilnbach Sulzbergerstrasse 14
      <br />
      Rosenheim
      <br />
      Deutschland
      <br />
      Tel. oder WhatsApp: 08666 98 94 90
      <br />
      E-Mail: elektrotherapie@yahoo.com
      <br />
      Webseite: www.tdcsrosenheim.de
      <br />
    </p>

    <h2>Allgemeines zur Datenverarbeitung</h2>
    <p></p>

    <h3>1. Umfang der Verarbeitung personenbezogener Daten</h3>
    <p>
      Wir erheben und verwenden personenbezogene Daten unserer Nutzer
      grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen
      Website sowie unserer Inhalte und Leistungen erforderlich ist. Die
      Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt
      regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in
      solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus
      tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten
      durch gesetzliche Vorschriften gestattet ist.
    </p>

    <h3>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
    <p>
      Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
      Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
      EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für die
      Verarbeitung personenbezogener Daten.
    </p>
    <p>
      Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines
      Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich
      ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch
      für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen
      erforderlich sind.
    </p>
    <p>
      Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
      rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
      unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
    </p>
    <p>
      Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
      einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten
      erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
    </p>
    <p>
      Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
      Unternehmens oder eines Dritten erforderlich und überwiegen die
      Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
      erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
      Rechtsgrundlage für die Verarbeitung.
    </p>

    <h3>3. Datenlöschung und Speicherdauer</h3>
    <p>
      Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
      gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann
      darüber hinaus dann erfolgen, wenn dies durch den europäischen oder
      nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
      sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
      wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine
      durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei
      denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für
      einen Vertragsabschluss oder eine Vertragserfüllung besteht.
    </p>

    <h2>Bereitstellung der Website und Erstellung von Logfiles</h2>
    <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
    <p>
      Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert
      Daten und Informationen vom Computersystem des aufrufenden Rechners.
    </p>
    <p>Folgende Daten werden hierbei erhoben:</p>
    <ol>
      <li>Informationen über den Browsertyp und die verwendete Version</li>
      <li>Das Betriebssystem des Nutzers</li>
      <li>Den Internet-Service-Provider des Nutzers</li>
      <li>Die IP-Adresse des Nutzers</li>
      <li>Datum und Uhrzeit des Zugriffs</li>
      <li>
        Websites, von denen das System des Nutzers auf unsere Internetseite
        gelangt
      </li>
      <li>
        Websites, die vom System des Nutzers über unsere Website aufgerufen
        werden
      </li>
    </ol>
    <p>
      Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert.
      Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten
      des Nutzers findet nicht statt
    </p>

    <h2>Rechtsgrundlage für die Datenverarbeitung</h2>
    <p>
      Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
      Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
    </p>

    <h2>Zweck der Datenverarbeitung</h2>
    <p>
      Die vorübergehende Speicherung der IP-Adresse durch das System ist
      notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu
      ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der
      Sitzung gespeichert bleiben.
    </p>
    <p>
      Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website
      sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website
      und zur Sicherstellung der Sicherheit unserer informationstechnischen
      Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem
      Zusammenhang nicht statt.
    </p>
    <p>
      In diesen Zwecken liegt auch unser berechtigtes Interesse an der
      Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
    </p>

    <h3>Dauer der Speicherung</h3>
    <p>
      Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
      Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten
      zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige
      Sitzung beendet ist.
    </p>
    <p>
      Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens
      sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich.
      In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet,
      sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
    </p>

    <h3>Widerspruchs- und Beseitigungsmöglichkeit</h3>
    <p>
      Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung
      der Daten in Logfiles ist für den Betrieb der Internetseite zwingend
      erforderlich. Es besteht folglich seitens des Nutzers keine
      Widerspruchsmöglichkeit.
    </p>

    <h2>Verwendung von Cookies</h2>
    <h3>a) Beschreibung und Umfang der Datenverarbeitung </h3>
    <p>
      Unsere Webeseite verwendet Cookies. Bei Cookies handelt es sich um
      Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
      Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
      Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
      gespeichert werden. Dieser Cookie enthält eine charakteristische
      Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
      erneuten Aufrufen der Website ermöglicht.{" "}
    </p>
    <p>
      Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten.
      Einige Elemente unserer Internetseite erfordern es, dass der aufrufende
      Browser auch nach einem Seitenwechsel identifiziert werden kann.{" "}
    </p>
    <p>
      In den Cookies werden dabei folgende Daten gespeichert und übermittelt:{" "}
    </p>
    <ol>
      <li>Spracheinstellungen</li>
      <li>Artikel in einem Warenkorb</li>
      <li>Log-In-Informationen</li>
    </ol>
    <p>
      Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse
      des Surfverhaltens der Nutzer ermöglichen. <br />
      Auf diese Weise können folgende Daten übermittelt werden:{" "}
    </p>
    <ol>
      <li>Eingegebene Suchbegriffe</li>
      <li>H&auml;ufigkeit von Seitenaufrufen</li>
      <li>Inanspruchnahme von Website-Funktionen</li>
    </ol>

    <p>
      Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
      Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
      aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam
      mit sonstigen personenbezogenen Daten der Nutzer gespeichert.{" "}
    </p>
    <p>
      Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner über
      die Verwendung von Cookies zu Analysezwecken informiert und auf diese
      Datenschutzerklärung verwiesen. Es erfolgt in diesem Zusammenhang auch ein
      Hinweis darauf, wie die Speicherung von Cookies in den
      Browsereinstellungen unterbunden werden kann.{" "}
    </p>
    <p>
      Beim Aufruf unserer Website wird der Nutzer über die Verwendung von
      Cookies zu Analysezwecken informiert und seine Einwilligung zur
      Verarbeitung der in diesem Zusammen verwendeten personenbezogenen Daten
      eingeholt.{" "}
    </p>
    <p>
      In diesem Zusammenhang erfolgt auch ein Hinweis auf diese
      Datenschutzerklärung.{" "}
    </p>
    <h3>b) Rechtsgrundlage für die Datenverarbeitung </h3>
    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
      Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.{" "}
    </p>
    <h3>c) Zweck der Datenverarbeitung </h3>
    <p>
      Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
      von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer
      Internetseite können ohne den Einsatz von Cookies nicht angeboten werden.
      Für diese ist es erforderlich, dass der Browser auch nach einem
      Seitenwechsel wiedererkannt wird.{" "}
    </p>

    <p>Für folgende Anwendungen benötigen wir Cookies: </p>
    <ol>
      <li>Warenkorb</li>
      <li>&Uuml;bernahme von Spracheinstellungen</li>
      <li>Merken von Suchbegriffen</li>
    </ol>

    <p>
      Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht
      zur Erstellung von Nutzerprofilen verwendet.{" "}
    </p>
    <p>
      Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität
      unserer Website und ihre Inhalte zu verbessern. Durch die Analyse-Cookies
      erfahren wir, wie die Website genutzt wird und können so unser Angebot
      stetig optimieren.{" "}
    </p>
    <p>
      In diesen Zwecken liegt auch unser berechtigtes Interesse in der
      Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.{" "}
    </p>

    <h3>
      e) Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit{" "}
    </h3>
    <p>
      Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an
      unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle
      Kontrolle über die Verwendung von Cookies. Durch eine Änderung der
      Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von
      Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies
      können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
      Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht
      mehr alle Funktionen der Website vollumfänglich genutzt werden.{" "}
    </p>
    <p>
      Die Übermittlung von Flash-Cookies lässt sich nicht über die Einstellungen
      des Browsers, jedoch durch Änderungen der Einstellung des Flash Players
      unterbinden.{" "}
    </p>
    <h3>f) Details zu einzelnen Anbietern </h3>

    <h3>1. Remarketing </h3>
    <h3>Facebook</h3>
    <p>
      Diese Website verwendet die Remarketing-Funktion „Custom Audiences“ der
      Facebook Inc. („Facebook“). Diese Funktion dient dazu, Besuchern dieser
      Webseite im Rahmen des Besuchs des sozialen Netzwerkes Facebook
      interessenbezogene Werbeanzeigen („Facebook-Ads“) zu präsentieren. Hierzu
      wurde auf dieser Website das Remarketing-Tag von Facebook implementiert.
      Über dieses Tag wird beim Besuch der Webseite eine direkte Verbindung zu
      den Facebook-Servern hergestellt. Dabei wird an den Facebook-Server
      übermittelt, dass Sie diese Website besucht haben und Facebook ordnet
      diese Information Ihrem persönlichen Facebook-Benutzerkonto zu. Nähere
      Informationen zur Erhebung und Nutzung der Daten durch Facebook sowie über
      Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer
      Privatsphäre finden Sie in den{" "}
      <a href="https://www.facebook.com/about/privacy/">
        Datenschutzhinweisen von Facebook
      </a>
      . Alternativ können Sie die Remarketing-Funktion „Custom Audiences“ auch{" "}
      <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">
        hier
      </a>{" "}
      deaktivieren. Hierfür müssen Sie bei Facebook angemeldet sein.{" "}
    </p>

    <h3>Google </h3>
    <p>
      Diese Website verwendet die Remarketing-Funktion der Google Inc.
      („Google“). Diese Funktion dient dazu, Besuchern der Website im Rahmen des
      Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu präsentieren.
      Der Browser des Websitebesuchers speichert sog. „Cookies“, Textdateien,
      die auf Ihrem Computer gespeichert werden und die es ermöglichen, den
      Besucher wiederzuerkennen, wenn dieser Websites aufruft, die dem
      Werbenetzwerk von Google angehören. Auf diesen Seiten können dem Besucher
      dann Werbeanzeigen präsentiert werden, die sich auf Inhalte beziehen, die
      der Besucher zuvor auf Websites aufgerufen hat, die die Remarketing
      Funktion von Google verwenden. Nach eigenen Angaben erhebt Google bei
      diesem Vorgang keine personenbezogenen Daten. Sollten Sie die Funktion
      Remarketing von Google dennoch nicht wünschen, können Sie diese
      grundsätzlich deaktivieren, indem Sie die entsprechenden Einstellungen
      unter{" "}
      <a href="http://www.google.com/settings/ads">
        {" "}
        http://www.google.com/settings/ads
      </a>
      vornehmen. Alternativ können Sie den Einsatz von Cookies für
      interessenbezogene Werbung über die Werbenetzwerkinitiative deaktivieren,
      indem Sie den Anweisungen unter{" "}
      <a href="http://www.networkadvertising.org/managing/opt_out.asp">
        http://www.networkadvertising.org/managing/opt_out.asp{" "}
      </a>
      folgen.{" "}
    </p>

    <h3>LinkedIn Retargeting </h3>
    <p>
      Unsere Website nutzt Funktionen des Netzwerk LinkedIn. Anbieter ist die
      LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA.
      Bei jedem Abruf einer unserer Seiten, die Funktionen von LinkedIn enthält,
      wird eine Verbindung zu Servern von LinkedIn aufgebaut. LinkedIn wird
      darüber informiert, dass Sie unsere Internetseiten mit Ihrer IP-Adresse
      besucht haben. Wenn Sie den "Recommend-Button" von LinkedIn anklicken und
      in Ihrem Account bei LinkedIn eingeloggt sind, ist es LinkedIn möglich,
      Ihren Besuch auf unserer Internetseite Ihnen und Ihrem Benutzerkonto
      zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
      Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch
      LinkedIn haben. Weitere Informationen hierzu finden Sie in der{" "}
      <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
        {" "}
        Datenschutzerklärung von LinkedIn.
      </a>
    </p>

    <p>
      Wenn Sie dieses Feature deaktivieren wollen klicken Sie bitte{" "}
      <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
        hier.
      </a>
    </p>
    <h3>2. Soziale Medien </h3>
    <h3>Facebook-Plugins (Like & Share-Button) </h3>
    <p>
      Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter
      Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA,
      integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder
      dem „Like-Button“ („Gefällt mir“) auf unserer Seite. Eine Übersicht über
      die Facebook-Plugins finden Sie hier:
      <a href="https://developers.facebook.com/docs/plugins/">
        https://developers.facebook.com/docs/plugins/.{" "}
      </a>{" "}
    </p>
    <p>
      Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
      Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
      Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse
      unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken
      während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die
      Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann
      Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir
      weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
      Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook
      erhalten. Weitere Informationen hierzu finden Sie in der
      Datenschutzerklärung von Facebook unter:
      <a href="https://de-de.facebook.com/policy.php">
        {" "}
        https://de-de.facebook.com/policy.php
      </a>
      . Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem
      Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
      Facebook-Benutzerkonto aus.{" "}
    </p>

    <h3>Instagram Plugin </h3>
    <p>
      Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden.
      Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow
      Road, Menlo Park, CA 94025, USA integriert.{" "}
    </p>
    <p>
      Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch
      Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem
      Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer
      Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als
      Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten
      sowie deren Nutzung durch Instagram erhalten.{" "}
    </p>
    <p>
      Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
      Instagram:
      <a href="https://instagram.com/about/legal/privacy/">
        {" "}
        https://instagram.com/about/legal/privacy/.{" "}
      </a>{" "}
    </p>

    <h3>Twitter Plugin </h3>
    <p>
      Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese
      Funktionen werden angeboten durch die Twitter Inc., 1355 Market Street,
      Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von Twitter
      und der Funktion „Re-Tweet“ werden die von Ihnen besuchten Websites mit
      Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei
      werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass wir
      als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten
      sowie deren Nutzung durch Twitter erhalten. Weitere Informationen hierzu
      finden Sie in der Datenschutzerklärung von Twitter unter:{" "}
      <a href="https://twitter.com/privacy"> https://twitter.com/privacy. </a>{" "}
    </p>
    <p>
      Ihre Datenschutzeinstellungen bei Twitter können Sie in den
      Konto-Einstellungen unter{" "}
      <a href="https://twitter.com/account/settings">
        {" "}
        https://twitter.com/account/settings{" "}
      </a>
      ändern.{" "}
    </p>

    <h3>YouTube </h3>
    <p>
      Mit dem YouTube-Player integrieren wir eigene Videos aus unseren
      YouTube-Channels oder Videos anderer Anbieter in unsere Seiten. Die
      Datenschutzhinweise von Google informieren Sie darüber, wie Google, der
      Eigentümer von YouTube, Ihre Daten erfasst und wie Google mit diesen Daten
      umgeht.{" "}
    </p>

    <h3>3. Analyse Tools und Werbung</h3>

    <h3>Google Analytics </h3>
    <p>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
      Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
      94043, USA. Google Analytics verwendet so genannte „Cookies“. Das sind
      Textdateien, die auf Ihrem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
      Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden
      in der Regel an einen Server von Google in den USA übertragen und dort
      gespeichert.{" "}
    </p>
    <p>
      Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von
      Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
      Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot
      als auch seine Werbung zu optimieren.{" "}
    </p>

    <h3>IP Anonymisierung </h3>
    <p>
      Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
      Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
      Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
      Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur
      in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
      den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
      Website wird Google diese Informationen benutzen, um Ihre Nutzung der
      Website auszuwerten, um Reports über die Websiteaktivitäten
      zusammenzustellen und um weitere mit der Websitenutzung und der
      Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber
      zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
      übermittelte IP-Adresse wird nicht mit anderen Daten von Google
      zusammengeführt.{" "}
    </p>

    <h3>Browser Plugin </h3>
    <p>
      Sie können die Speicherung der Cookies durch eine entsprechende
      Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
      darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
      Funktionen dieser Website vollumfänglich werden nutzen können. Sie können
      darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre
      Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
      sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das
      unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
      installieren:
      <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
        {" "}
        https://tools.google.com/dlpage/gaoptout?hl=de.{" "}
      </a>{" "}
    </p>

    <h3>Widerspruch gegen Datenerfassung </h3>
    <p>
      Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
      indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt,
      der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website
      verhindert: deactivate Google Analytics <br />
      Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden
      Sie in der Datenschutzerklärung von Google:
      <a href="https://support.google.com/analytics/answer/6004245?hl=de">
        {" "}
        https://support.google.com/analytics/answer/6004245?hl=de.{" "}
      </a>{" "}
    </p>

    <h3>Auftragsdatenverarbeitung </h3>
    <p>
      Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
      abgeschlossen und setzen die strengen Vorgaben der deutschen
      Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.{" "}
    </p>

    <h3>Demografische Merkmale bei Google Analytics </h3>
    <p>
      Diese Website nutzt die Funktion “demografische Merkmale” von Google
      Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter,
      Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten
      stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten
      von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet
      werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen
      in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch
      Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung”
      dargestellt generell untersagen.{" "}
    </p>

    <h3>4. Sonstige </h3>
    <h3>Google reCAPTCHA </h3>
    <p>
      Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren
      Websites. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
      Mountain View, CA 94043, USA (“Google”).{" "}
    </p>
    <p>
      Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren
      Websites (z.B. in einem Kontaktformular) durch einen Menschen oder durch
      ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das
      Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese
      Analyse beginnt automatisch, sobald der Websitebesucher die Website
      betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B.
      IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom
      Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten
      werden an Google weitergeleitet.{" "}
    </p>
    <p>
      Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher
      werden nicht darauf hingewiesen, dass eine Analyse stattfindet.{" "}
    </p>
    <p>
      Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
      DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine
      Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM
      zu schützen.{" "}
    </p>
    <p>
      Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung
      von Google entnehmen Sie folgenden Links:
      <a href="https://www.google.com/intl/de/policies/privacy/">
        {" "}
        https://www.google.com/intl/de/policies/privacy/{" "}
      </a>{" "}
      und{" "}
      <a href="https://www.google.com/recaptcha/intro/android.htm">
        {" "}
        https://www.google.com/recaptcha/intro/android.html.{" "}
      </a>{" "}
    </p>

    <h3>Google Web Fonts </h3>
    <p>
      Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
      genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
      einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
      Browsercache, um Texte und Schriftarten korrekt anzuzeigen.{" "}
    </p>
    <p>
      Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
      Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
      dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von
      Google Web Fonts erfolgt im Interesse einer einheitlichen und
      ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
      berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.{" "}
    </p>
    <p>
      Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
      von Ihrem Computer genutzt.{" "}
    </p>
    <p>
      Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
      <a href="https://developers.google.com/fonts/faq">
        {" "}
        https://developers.google.com/fonts/faq{" "}
      </a>{" "}
      und in der Datenschutzerklärung von Google:
      <a href="https://www.google.com/policies/privacy/">
        {" "}
        https://www.google.com/policies/privacy/.{" "}
      </a>{" "}
    </p>

    <h2>Newsletter </h2>
    <h3>1. Beschreibung und Umfang der Datenverarbeitung </h3>
    <p>
      Auf unserer Internetseite besteht die Möglichkeit einen kostenfreien
      Newsletter zu abonnieren. Dabei werden bei der Anmeldung zum Newsletter
      die Daten aus der Eingabemaske an uns übermittelt.{" "}
    </p>
    <p>Zudem werden folgende Daten bei der Anmeldung erhoben: </p>
    <ol>
      <li>IP-Adresse des aufrufenden Rechners </li>
      <li>Datum und Uhrzeit der Registrierung </li>
    </ol>
    <p>
      Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgang Ihre
      Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen. Wenn
      Sie auf unserer Internetseite Waren oder Dienstleistungen erwerben und
      hierbei Ihre E-Mail-Adresse hinterlegen, kann diese in der Folge durch uns
      für den Versand eines Newsletters verwendet werden. In einem solchen Fall
      wird über den Newsletter ausschließlich Direktwerbung für eigene ähnliche
      Waren oder Dienstleistungen versendet.{" "}
    </p>
    <p>
      Es erfolgt im Zusammenhang mit der Datenverarbeitung für den Versand von
      Newslettern keine Weitergabe der Daten an Dritte. Die Daten werden
      ausschließlich für den Versand des Newsletters verwendet.{" "}
    </p>

    <h3>2. Rechtsgrundlage für die Datenverarbeitung </h3>
    <p>
      Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum
      Newsletters durch den Nutzer ist bei Vorliegen einer Einwilligung des
      Nutzers Art. 6 Abs. 1 lit. a DSGVO.{" "}
    </p>
    <p>
      Rechtsgrundlage für den Versand des Newsletters infolge des Verkaufs von
      Waren oder Dienstleistungen ist § 7 Abs. 3 UWG.{" "}
    </p>
    <h3>3. Zweck der Datenverarbeitung </h3>
    <p>
      Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter
      zuzustellen.{" "}
    </p>
    <p>
      Die Erhebung sonstiger personenbezogener Daten im Rahmen des
      Anmeldevorgangs dient dazu, einen Missbrauch der Dienste oder der
      verwendeten E-Mail-Adresse zu verhindern.{" "}
    </p>
    <h3>4. Dauer der Speicherung </h3>
    <p>
      Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
      Erhebung nicht mehr erforderlich sind. Die E-Mail-Adresse des Nutzers wird
      demnach solange gespeichert, wie das Abonnement des Newsletters aktiv ist.{" "}
    </p>
    <p>
      Die sonstigen im Rahmen des Anmeldevorgangs erhobenen personenbezogenen
      Daten werden in der Regel nach einer Frist von sieben Tagen gelöscht.{" "}
    </p>
    <h3>5. Widerspruchs- und Beseitigungsmöglichkeit </h3>
    <p>
      Das Abonnement des Newsletters kann durch den betroffenen Nutzer jederzeit
      gekündigt werden. Zu diesem Zweck findet sich in jedem Newsletter ein
      entsprechender Link.{" "}
    </p>
    <p>
      Hierdurch wird ebenfalls ein Widerruf der Einwilligung der Speicherung der
      während des Anmeldevorgangs erhobenen personenbezogenen Daten ermöglicht.{" "}
    </p>

    <h2>Registrierung </h2>
    <h3>1. Beschreibung und Umfang der Datenverarbeitung </h3>
    <p>
      Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich unter
      Angabe personenbezogener Daten zu registrieren. Die Daten werden dabei in
      eine Eingabemaske eingegeben und an uns übermittelt und gespeichert. Eine
      Weitergabe der Daten an Dritte findet nicht statt. Folgende Daten werden
      im Rahmen des Registrierungsprozesses erhoben:{" "}
    </p>
    <p>
      Im Zeitpunkt der Registrierung werden zudem folgende Daten gespeichert:{" "}
    </p>
    <ol>
      <li>Die IP-Adresse des Nutzers</li>
      <li>Datum und Uhrzeit der Registrierung </li>
    </ol>

    <p>
      Im Rahmen des Registrierungsprozesses wird eine Einwilligung des Nutzers
      zur Verarbeitung dieser Daten eingeholt.{" "}
    </p>
    <h3>6. Rechtsgrundlage für die Datenverarbeitung </h3>
    <p>
      Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
      Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.{" "}
    </p>
    <p>
      Dient die Registrierung der Erfüllung eines Vertrages, dessen
      Vertragspartei der Nutzer ist oder der Durchführung vorvertraglicher
      Maßnahmen, so ist zusätzliche Rechtsgrundlage für die Verarbeitung der
      Daten Art. 6 Abs. 1 lit. b DSGVO.{" "}
    </p>
    <h3>7. Zweck der Datenverarbeitung </h3>
    <p>
      Eine Registrierung des Nutzers ist für das Bereithalten bestimmter Inhalte
      und Leistungen auf unserer Website erforderlich.{" "}
    </p>
    <p>
      Eine Registrierung des Nutzers ist zur Erfüllung eines Vertrages mit dem
      Nutzer oder zur Durchführung vorvertraglicher Maßnahmen erforderlich.{" "}
    </p>

    <h3>8. Dauer der Speicherung </h3>
    <p>
      Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
      Erhebung nicht mehr erforderlich sind.{" "}
    </p>
    <p>
      Dies ist für die während des Registrierungsvorgangs erhobenen Daten der
      Fall, wenn die Registrierung auf unserer Internetseite aufgehoben oder
      abgeändert wird.{" "}
    </p>
    <p>
      Dies ist für die während des Registrierungsvorgangs zur Erfüllung eines
      Vertrags oder zur Durchführung vorvertraglicher Maßnahmen dann der Fall,
      wenn die Daten für die Durchführung des Vertrages nicht mehr erforderlich
      sind. Auch nach Abschluss des Vertrags kann eine Erforderlichkeit,
      personenbezogene Daten des Vertragspartners zu speichern, bestehen, um
      vertraglichen oder gesetzlichen Verpflichtungen nachzukommen.{" "}
    </p>

    <h3>9. Widerspruchs- und Beseitigungsmöglichkeit </h3>
    <p>
      Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung
      aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit abändern
      lassen.{" "}
    </p>
    <p>
      Sind die Daten zur Erfüllung eines Vertrages oder zur Durchführung
      vorvertraglicher Maßnahmen erforderlich, ist eine vorzeitige Löschung der
      Daten nur möglich, soweit nicht vertragliche oder gesetzliche
      Verpflichtungen einer Löschung entgegenstehen.{" "}
    </p>

    <h2>Kontaktformular und E-Mail-Kontakt </h2>
    <h3>1. Beschreibung und Umfang der Datenverarbeitung </h3>
    <p>
      Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für
      die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer
      diese Möglichkeit war, so werden die in der Eingabemaske eingegeben Daten
      an uns übermittelt und gespeichert. Diese Daten sind:{" "}
    </p>
    <p>
      Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten
      gespeichert:{" "}
    </p>
    <ol>
      <li>Die IP-Adresse des Nutzers</li>
      <li>Datum und Uhrzeit der Registrierung </li>
    </ol>
    <p>
      Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre
      Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.{" "}
    </p>
    <p>
      Alternativ ist eine Kontaktaufnahme über die bereitgestellte
      E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail
      übermittelten personenbezogenen Daten des Nutzers gespeichert.{" "}
    </p>
    <p>
      Es verfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte.
      Die Daten werden ausschließlich für die Verarbeitung der Konversation
      verwendet.{" "}
    </p>

    <h3>10. Rechtsgrundlage für die Datenverarbeitung </h3>
    <p>
      Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
      Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.{" "}
    </p>
    <p>
      Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
      Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f
      DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so
      ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
      DSGVO.{" "}
    </p>
    <h3>11. Zweck der Datenverarbeitung </h3>
    <p>
      Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient
      uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
      Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte
      Interesse an der Verarbeitung der Daten.{" "}
    </p>
    <p>
      Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen
      Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und
      die Sicherheit unserer informationstechnischen Systeme sicherzustellen.{" "}
    </p>

    <h3>12. Dauer der Speicherung </h3>
    <p>
      Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
      Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus
      der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail
      übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation
      mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich
      aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt
      abschließend geklärt ist.{" "}
    </p>
    <p>
      Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen
      Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.{" "}
    </p>
    <h3>13. Widerspruchs- und Beseitigungsmöglichkeit </h3>
    <p>
      Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
      Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer
      per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner
      personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall
      kann die Konversation nicht fortgeführt werden.{" "}
    </p>
    <p>
      Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert
      wurden, werden in diesem Fall gelöscht.{" "}
    </p>

    <h2>Rechte der betroffenen Person </h2>
    <p>
      Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener
      i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem
      Verantwortlichen zu:{" "}
    </p>
    <h3>1. Auskunftsrecht </h3>
    <p>
      Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob
      personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.{" "}
    </p>
    <p>
      Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen
      über folgende Informationen Auskunft verlangen:{" "}
    </p>
    <ol>
      <li>
        die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden
      </li>
      <li>
        die Kategorien von personenbezogenen Daten, welche verarbeitet werden
      </li>
      <li>
        die Empf&auml;nger bzw. die Kategorien von Empf&auml;ngern,
        gegen&uuml;ber denen die Sie betreffenden personenbezogenen Daten
        offengelegt wurden oder noch offengelegt werden
      </li>
      <li>
        die geplante Dauer der Speicherung der Sie betreffenden
        personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
        m&ouml;glich sind, Kriterien f&uuml;r die Festlegung der Speicherdauer
      </li>
      <li>
        das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der Sie
        betreffenden personenbezogenen Daten, eines Rechts auf
        Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen oder
        eines Widerspruchsrechts gegen diese Verarbeitung
      </li>
      <li>
        das Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde
      </li>
      <li>
        alle verf&uuml;gbaren Informationen &uuml;ber die Herkunft der Daten,
        wenn die personenbezogenen Daten nicht bei der betroffenen Person
        erhoben werden
      </li>
      <li>
        das Bestehen einer automatisierten Entscheidungsfindung
        einschlie&szlig;lich Profiling gem&auml;&szlig; Art. 22 Abs. 1 und 4
        DSGVO und &ndash; zumindest in diesen F&auml;llen &ndash;
        aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik sowie
        die Tragweite und die angestrebten Auswirkungen einer derartigen
        Verarbeitung f&uuml;r die betroffene Person.
      </li>
    </ol>
    <p>
      Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
      betreffenden personenbezogenen Daten in ein Drittland oder an eine
      internationale Organisation übermittelt werden. In diesem Zusammenhang
      können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im
      Zusammenhang mit der Übermittlung unterrichtet zu werden.{" "}
    </p>

    <h3>14. Recht auf Berichtigung </h3>
    <p>
      Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber
      dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten,
      die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche
      hat die Berichtigung unverzüglich vorzunehmen.{" "}
    </p>

    <h3>15. Recht auf Einschränkung der Verarbeitung </h3>
    <p>
      Unter den folgenden Voraussetzungen können Sie die Einschränkung der
      Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:{" "}
    </p>
    <ol>
      <li>
        wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen f&uuml;r
        eine Dauer bestreiten, die es dem Verantwortlichen erm&ouml;glicht, die
        Richtigkeit der personenbezogenen Daten zu &uuml;berpr&uuml;fen
      </li>
      <li>
        die Verarbeitung unrechtm&auml;&szlig;ig ist und Sie die L&ouml;schung
        der personenbezogenen Daten ablehnen und stattdessen die
        Einschr&auml;nkung der Nutzung der personenbezogenen Daten verlangen
      </li>
      <li>
        der Verantwortliche die personenbezogenen Daten f&uuml;r die Zwecke der
        Verarbeitung nicht l&auml;nger ben&ouml;tigt, Sie diese jedoch zur
        Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen ben&ouml;tigen, oder
      </li>
      <li>
        wenn Sie Widerspruch gegen die Verarbeitung gem&auml;&szlig; Art. 21
        Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die
        berechtigten Gr&uuml;nde des Verantwortlichen gegen&uuml;ber Ihren
        Gr&uuml;nden &uuml;berwiegen.
      </li>
    </ol>
    <p>
      Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
      eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur
      mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
      von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
      oder juristischen Person oder aus Gründen eines wichtigen öffentlichen
      Interesses der Union oder eines Mitgliedstaats verarbeitet werden.{" "}
    </p>
    <p>
      Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
      eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die
      Einschränkung aufgehoben wird.{" "}
    </p>

    <h3>16. Recht auf Löschung </h3>
    <h3>a) Löschungspflicht </h3>
    <p>
      Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden
      personenbezogenen Daten unverzüglich gelöscht werden, und der
      Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen,
      sofern einer der folgenden Gründe zutrifft:{" "}
    </p>
    <ol>
      <li>
        Die Sie betreffenden personenbezogenen Daten sind f&uuml;r die Zwecke,
        f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden,
        nicht mehr notwendig.
      </li>
      <li>
        Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
        Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO st&uuml;tzte, und
        es fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r die
        Verarbeitung.
      </li>
      <li>
        Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung
        ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r
        die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
        Widerspruch gegen die Verarbeitung ein.
      </li>
      <li>
        Die Sie betreffenden personenbezogenen Daten wurden
        unrechtm&auml;&szlig;ig verarbeitet.
      </li>
      <li>
        Die L&ouml;schung der Sie betreffenden personenbezogenen Daten ist zur
        Erf&uuml;llung einer rechtlichen Verpflichtung nach dem Unionsrecht oder
        dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
        unterliegt.
      </li>
      <li>
        Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
        angebotene Dienste der Informationsgesellschaft gem&auml;&szlig; Art. 8
        Abs. 1 DSGVO erhoben.
      </li>
    </ol>

    <h2>Information an Dritte </h2>
    <p>
      Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten
      öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung
      verpflichtet, so trifft er unter Berücksichtigung der verfügbaren
      Technologie und der Implementierungskosten angemessene Maßnahmen, auch
      technischer Art, um für die Datenverarbeitung Verantwortliche, die die
      personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als
      betroffene Person von ihnen die Löschung aller Links zu diesen
      personenbezogenen Daten oder von Kopien oder Replikationen dieser
      personenbezogenen Daten verlangt haben.{" "}
    </p>

    <h2>Ausnahmen </h2>
    <p>
      Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich
      ist{" "}
    </p>
    <ol>
      <li>
        zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und
        Information
      </li>
      <li>
        zur Erf&uuml;llung einer rechtlichen Verpflichtung, die die Verarbeitung
        nach dem Recht der Union oder der Mitgliedstaaten, dem der
        Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
        Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung
        &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen
        &uuml;bertragen wurde
      </li>
      <li>
        aus Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich der
        &ouml;ffentlichen Gesundheit gem&auml;&szlig; Art. 9 Abs. 2 lit. h und i
        sowie Art. 9 Abs. 3 DSGVO
      </li>
      <li>
        f&uuml;r im &ouml;ffentlichen Interesse liegende Archivzwecke,
        wissenschaftliche oder historische Forschungszwecke oder f&uuml;r
        statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
        Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele
        dieser Verarbeitung unm&ouml;glich macht oder ernsthaft
        beeintr&auml;chtigt, oder
      </li>
      <li>
        zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen.
      </li>
    </ol>

    <h3>17. Recht auf Unterrichtung </h3>
    <p>
      Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
      Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
      verpflichtet, allen Empfängern, denen die Sie betreffenden
      personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
      Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei
      denn, dies erweist sich als unmöglich oder ist mit einem
      unverhältnismäßigen Aufwand verbunden.{" "}
    </p>
    <p>
      Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
      Empfänger unterrichtet zu werden.{" "}
    </p>

    <h3>18. Recht auf Datenübertragbarkeit </h3>
    <p>
      Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie
      dem Verantwortlichen bereitgestellt haben, in einem strukturierten,
      gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das
      Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch
      den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
      wurden, zu übermitteln, sofern{" "}
    </p>
    <ol>
      <li>
        die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO
        oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs.
        1 lit. b DSGVO beruht und{" "}
      </li>
      <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt. </li>{" "}
    </ol>
    <p>
      In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
      die Sie betreffenden personenbezogenen Daten direkt von einem
      Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit
      dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen
      hierdurch nicht beeinträchtigt werden.{" "}
    </p>
    <p>
      Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
      personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
      erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
      öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.{" "}
    </p>
    <h3>19. Widerspruchsrecht </h3>
    <p>
      Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation
      ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
      personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
      DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
      Bestimmungen gestütztes Profiling.{" "}
    </p>
    <p>
      Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
      Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für
      die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
      überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
      Verteidigung von Rechtsansprüchen.{" "}
    </p>
    <p>
      Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
      Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
      gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
      Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
      soweit es mit solcher Direktwerbung in Verbindung steht.{" "}
    </p>
    <p>
      Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden
      die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke
      verarbeitet.{" "}
    </p>
    <p>
      Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten
      der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr
      Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen
      technische Spezifikationen verwendet werden.{" "}
    </p>

    <h3>
      20. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung{" "}
    </h3>
    <p>
      Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
      jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
      Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
      Verarbeitung nicht berührt.{" "}
    </p>
    <h3>
      21. Automatisierte Entscheidung im Einzelfall einschließlich Profiling{" "}
    </h3>
    <p>
      Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten
      Verarbeitung – einschließlich Profiling – beruhenden Entscheidung
      unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
      oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht,
      wenn die Entscheidung{" "}
    </p>
    <ol>
      <li>
        für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und
        dem Verantwortlichen erforderlich ist,{" "}
      </li>
      <li>
        aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
        denen der Verantwortliche unterliegt, zulässig ist und diese
        Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
        Freiheiten sowie Ihrer berechtigten Interessen enthalten oder{" "}
      </li>
      <li>mit Ihrer ausdrücklichen Einwilligung erfolgt. </li>
    </ol>
    <p>
      Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
      personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht
      Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der
      Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen
      wurden.
    </p>
    <p>
      Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche
      angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre
      berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung
      des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung
      des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.{" "}
    </p>

    <h3>22. Recht auf Beschwerde bei einer Aufsichtsbehörde </h3>
    <p>
      Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
      Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer
      Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts,
      ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn
      Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
      personenbezogenen Daten gegen die DSGVO verstößt.{" "}
    </p>
    <p>
      Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
      unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
      Beschwerde einschließlich der Möglichkeit eines gerichtlichen
      Rechtsbehelfs nach Art. 78 DSGVO.{" "}
    </p>
  </Layout>
)

export default Datenschutz
